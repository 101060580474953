import React, { useContext } from 'react'
import styled from 'styled-components'
import { borders } from '../../theme'
import {
  Button,
  TimePicker,
  StyledTimePicker,
  Pulse,
} from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { EventContext } from '../../components/context/EventContext'
import { navigate } from '../../components/Link'
import { useRedirect } from '../../hooks/useRedirect'
import { useNoValidate } from '../../hooks/useNoValidate'
import * as yup from 'yup'
import { useFormalWithProps } from '../../hooks/useFormalWithProps'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const schema = yup.object().shape({
  startDateTime: yup.date().nullable(),
  endDateTime: yup.date().nullable(),
})

const StartHourPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { startDateTime, endDateTime },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const noValidate = useNoValidate()

  const submit = () => {
    navigate(computeNextPageWithBaseUri('demande', 'debut'), {
      state: { next: true },
    })
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  const { formal } = useFormalWithProps(
    {
      startDateTime,
      endDateTime,
    },
    {
      schema,
      onSubmit: submitWithDelay,
    },
  )

  return (
    <StyledStartHourPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`À partir de quelle heure\u00A0?`}
              subTitle={`Merci de renseigner l'heure à laquelle le banquet doit être servi 
                            et l'heure à laquelle il doit se terminer`}
            />

            <form
              noValidate={noValidate}
              onSubmit={async e => {
                e.preventDefault()
                try {
                  await formal.validate()
                  await formal.submit()
                } catch (err) {
                  console.info(err)
                }
              }}
            >
              <TimePicker
                value={startDateTime}
                minTime={{ hours: 7, minutes: 0 }}
                maxTime={{ hours: 21, minutes: 0 }}
                onChange={sDateTime =>
                  dispatchEventCtx({
                    type: 'UPDATE_DELIVERY_DATETIME',
                    payload: {
                      startDateTime: sDateTime,
                      endDateTime: endDateTime,
                    },
                  })
                }
              />

              <TimePicker
                value={endDateTime}
                minTime={{ hours: 7, minutes: 0 }}
                maxTime={{ hours: 23, minutes: 45 }}
                onChange={eDateTime =>
                  dispatchEventCtx({
                    type: 'UPDATE_DELIVERY_DATETIME',
                    payload: {
                      endDateTime: eDateTime,
                      startDateTime: startDateTime,
                    },
                  })
                }
              />

              <div className="btn-bar">
                <Button
                  id="previous"
                  className="previous btn-secondary"
                  onClick={() => navigate(-1)}
                >
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledStartHourPage>
  )
}

const StyledStartHourPage = styled.div`
  .page-content {
    max-width: 500px;
  }

  ${StyledTimePicker} {
    margin: 40px auto;
  }

  .btn-secondary {
    background: transparent;
    border: 1px solid ${borders.color.light};
  }
`

export default StartHourPage
export { StyledStartHourPage }
